<template>
  <v-col class="page page-navigation pa-0 mb-3 mt-5">
    <v-col class="headline font-weight-light pl-3">Administratie</v-col>
    <v-container class="pa-0" fluid>
      <v-row>
        <navigation-block class="navigation-block" :item="item" :key="item.id" v-for="item in items"/>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import NavigationBlock from '../components/NavigationBlock.vue';

export default {
  components: {
    NavigationBlock,
  },
  name: 'Navigation',
  data() {
    return {
      items: [],
      activity: false,
      name: null,
    };
  },
  created() {
    let url = 'navigation/' + this.$route.params.navigationId + '/navigation';
    this.activity = true;
    this.$http.get(url, {
          params: {
            sortBy: 'order',
            desc: 0,
            page: 1,
            perPage: 10000,
          },
        })
        .then((response) => {
          this.items = response.data.data;
          this.activity = false;
        });
  },
};
</script>

<style scoped>
.navigation-block {
  cursor: pointer;
}
</style>
